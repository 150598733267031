body {
  margin: 0;
  background-color: #363648;
  font-family: "Open Sans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

h1 {
  font-size: 8rem;
  padding: 1rem;
  height: 100%;
  color: #c48199;
  text-align: center;
}

.no {
  color: #f36899;
}

.yes {
  color: #ade895;
}

.soon {
  color: #d8b432;
}

h3 {
  white-space: nowrap;
  color: #cec8ca;
  align-self: center;
  margin: 0 4rem;
  font-size: 1.2rem;
}

.countdown {
  font-weight: 100;
  text-align: center;
  font-size: 4rem;
  width: 100%;
  line-height: normal;
  color: white;
}

.clock-container {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  display: inline-block;
}

.clock-label {
  color: #cec8ca;
  padding: 2px 15px;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 0.1rem;
}

.clock-small-text {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
}

.timeLeft {
  display: flex;
  flex-direction: row;
}

.fade-in {
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

@media screen and (max-width: 1300px) {
  .clock-container {
    padding: 0.5rem;
  }

  h1 {
    font-size: 6rem;
  }

  h3 {
    margin: 0 2rem;
  }
}

@media screen and (max-width: 900px) {
  h1 {
    font-size: 4rem;
  }

  .timeLeft {
    flex-direction: column;
  }

  .countdown {
    margin: 2rem 0;
  }
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1rem;
  }

  .countdown {
    font-size: 2rem;
    margin: 1rem 0;
  }

  .clock-small-text {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 350px) {
  h1 {
    font-size: 2rem;
  }

  h3 {
    font-size: 0.8rem;
  }

  .countdown {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  .clock-small-text {
    font-size: 0.7rem;
  }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

[class|="confetti"] {
  position: absolute;
}

.red {
  background-color: #E94A3F;
}

.yellow {
  background-color: #FAA040;
}

.blue {
  background-color: #5FC9F5;
}

.confettiHolder {
  top: 0;
  left: 0;
  position: absolute;
}

.hiddenConfetti {
  display: none;
}